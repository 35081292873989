<template>

  <div class="type-singlechoice-image">
    <div class="table">
      <div class="col">
        <transition-group name="ts-section" tag="div">
          <div class=""
               v-show="item.index === question.currentItem"
               :key="item.id"
               v-for="item in question.items">
            <div class="img-expander-wrapper">
              <div class="img-expander-holder">
                <div class="img-expander space-bottom-xs" @click="expandImage(item.image.sizes.full)">
                  <div class="title text-info color-black space-bottom-xs">{{test.titleFullscreen}}
                    <div class="icon icon-expand"></div>
                  </div>
                  <div class="img">
                    <img class="img-src"
                         :class="item.image.orientation"
                         :srcset="item.image.srcset"
                         :src="item.image.sizes.full" />
                  </div>
                </div>
                <div v-if="question.items.length > 1" class="color-black text-info">{{question.currentItem + 1}} /
                  {{question.items.length}}</div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
      <div class="col">
        <div class="text-info ani-opacity--3 color-black hide-mobile">{{test.titleSinglechoice}}</div>
        <div class="answers" :class="{'min' : question.answers.length < 4}">
          <div class="answer"
               :style="{transitionDelay: ((index * 0.1) +0.1) +  's'}"
               :key="item.id"
               v-for="(item, index) in question.answers">
            <button class="answer-button" @click="clickHandle(item.id)" :class="{'active' : data.isAnswer(item.id)}">
              <div class="text-title-small title color-black">{{item.title}}</div>
              <div class="icon icon-add"></div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "SetQuestionSinglechoiceImage",
  props: [
    'index',
    'sindex',
  ],
  computed: {
    imgModal() {
      return this.$store.state.controller.imgmodal;
    },
    test() {
      return this.$store.state.controller.test;
    },
    set() {
      return this.$store.state.controller.test.sets[this.sindex];
    },
    question() {
      return this.$store.state.controller.test.sets[this.sindex].questions[this.index];
    },
    data() {
      return this.$store.state.controller.test.sets[this.sindex].questions[this.index].items[this.question.currentItem];
    }
  },
  methods: {
    clickHandle(id) {
      if(this.data.hasAnswer(id)) {
        this.data.removeAnswer(id);
      } else {
        this.data.addAnswers(id);
      }
    },
    expandImage(src) {
      this.imgModal.setSrc(src);
      this.imgModal.setActive(true);
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/question-singlechoice';
@import '~@/styles/table';
</style>