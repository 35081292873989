import { render, staticRenderFns } from "./SetQuestionSinglechoiceImage.vue?vue&type=template&id=82d4f3ec&scoped=true"
import script from "./SetQuestionSinglechoiceImage.vue?vue&type=script&lang=js"
export * from "./SetQuestionSinglechoiceImage.vue?vue&type=script&lang=js"
import style0 from "./SetQuestionSinglechoiceImage.vue?vue&type=style&index=0&id=82d4f3ec&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82d4f3ec",
  null
  
)

export default component.exports