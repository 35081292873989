import QuestionItem from "@/classes/QuestionItem";

export default class QuestionTypeFolderItem extends QuestionItem {

    constructor() {

        super();
        this.image = "";
        this.srcset = "";

    }

    build(construct, index, question) {

        super.build(construct, index, question);
        this.image = construct.image;
        this.srcset = construct.srcset;

    }

}