export default class ModalInfo {

    constructor() {

        this.title = '';
        this.headline = '';
        this.text = '';

    }

}