import Question from "@/classes/Question";
import QuestionTypeSwipeItem from "@/classes/QuestionTypeSwipeItem";

export default class QuestionTypeSwipe extends Question {

    constructor() {

        super();
        this.type = "swipe";
        this.optional = [];

    }

    build(construct, index, num) {

        super.build(construct, index, num);

        this.optional = construct.screen.optional;

        let i = 0;
        while(i < construct.items.length) {

            if(construct.items[i].image !== "") {

                this.type = "swipe-image";

            }

            let item = new QuestionTypeSwipeItem();
            item.build(construct.items[i], i, this);
            this.items.push(item);

            i = i + 1;

        }
        
    }

}