<template>

  <div class="type-folder">
    <div class="table">
      <div class="col">
        <transition-group name="ts-section" tag="div">
            <div class=""
                 v-show="item.index === question.currentItem"
                 :key="item.id"
                 v-for="item in question.items">
              <div class="img-expander-wrapper">
                <Drag class="img-expander-holder"
                      @dragstart="handleDragStart"
                      @dragend="handleDragEnd">
                  <div class="img-expander space-bottom-xs" @click="expandImage(item.image.sizes.full)">
                    <div class="title text-info color-black space-bottom-xs">{{test.titleFullscreen}}
                      <div class="icon icon-expand"></div>
                    </div>
                    <div class="img drag">
                      <img class="img-src"
                           :class="item.image.orientation"
                           :srcset="item.image.srcset"
                           :src="item.image.sizes.full"
                           v-if="item.image !== ''" />
                    </div>
                  </div>
                  <div v-if="question.items.length > 1" class="color-black text-info">
                    {{question.currentItem + 1}} /
                    {{question.items.length}}
                  </div>
                </Drag>
              </div>
            </div>
        </transition-group>
      </div>
      <div class="col">
        <div class="text-info ani-opacity--3 color-black space-bottom-sm hide-mobile">{{test.titleFolder}}</div>
        <div class="answers" :class="{'min' : question.answers.length < 4}">
          <div class="answer"
               :style="{transitionDelay: ((index * 0.1) +0.1) +  's'}"
               :key="item.id"
               v-for="(item, index) in question.answers">
            <Drop @drop="handleDrop" @dragleave="handleDragLeave" @dragenter="handleDragEnter">
              <button @click="clickHandle(item.id)" class="answer-button" :class="{'active' : data.isAnswer(item.id)}">
                <div class="icon"></div>
                <div class="text-title-small title color-black">{{item.title}}</div>
                <div class="num text-title-small color-black">{{question.getNum(item.id)}}</div>
              </button>
            </Drop>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { Drag, Drop } from 'vue-drag-drop';

export default {
  name: "SetQuestionFolder",
  components: {
    Drag,
    Drop
  },
  props: [
      'index',
      'sindex',
  ],
  computed: {
    imgModal() {
       return this.$store.state.controller.imgmodal;
    },
    test() {
      return this.$store.state.controller.test;
    },
    set() {
      return this.$store.state.controller.test.sets[this.sindex];
    },
    question() {
      return this.$store.state.controller.test.sets[this.sindex].questions[this.index];
    },
    data() {
      return this.$store.state.controller.test.sets[this.sindex].questions[this.index].items[this.question.currentItem];
    }
  },
  methods: {
    handleDrop(droppedElement, e){
      let ele = e.target;
      if (document.createEvent) {
        let evt = document.createEvent('MouseEvents');
        evt.initEvent('click', true, false);
        ele.dispatchEvent(evt);
      } else if (document.createEventObject) {
        ele.fireEvent('onclick') ;
      } else if (typeof node.onclick == 'function') {
        ele.onclick();
      }
    },
    handleDragEnter(droppedElement, e) {
      e.target.classList.add("over");
    },
    handleDragLeave(droppedElement, e) {
      e.target.classList.remove("over");
    },
    handleDragStart(draggedElement, e) {
      e.srcElement.classList.add("drag");
    },
    handleDragEnd(draggedElement, e) {
      e.srcElement.classList.remove("drag");
    },
    expandImage(src) {
      this.imgModal.setSrc(src);
      this.imgModal.setActive(true);
    },
    clickHandle(id) {

      this.data.addAnswers(id);
   
      let overs = document.querySelectorAll(".over");
      let i = 0;
      while(i < overs.length) {
        overs[i].classList.remove("over");
        i = i +1;
      }
      this.question.next();
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/question-folder';
@import '~@/styles/table';
@import '~@/styles/image';
</style>