import store from '../store';
import axios from "axios";

export default class Result{

    constructor(){

        this.state = "start";

        this.currentEvaluationQuestion = 0;
        this.currentEvaluationSet = 0;

        this.startMessage = "";
        this.startTitle = "";
        this.startInfo = "";
        this.startImage = "";

        this.detailTitle = "";
        this.detailText = "";
        this.buttonAverage = "";

        this.startPoints = [];
        this.maxPoints = 0;

        this.showAverage = false;

        this.evaluationTitle = "";
        this.evaluationTable1 = "";
        this.evaluationTable2 = "";
        this.evaluationTable3 = "";
        this.buttonEvaluationNext = "";
        this.buttonEvaluationPrev = "";

        this.modalTitle = "";

        this.buttonDetails = "";
        this.buttonDownload = "";
        this.pdf = "";
        this.pdfUri = "";
        this.pdfPost = "";

        this.average = [];
        this.averageBg = '';
        this.userPoints = 0;

        this.maxPoints = 0;

    }

    switchShowAverage() {

        if(this.showAverage) {

            this.showAverage = false;

        } else {

            this.showAverage = true;

        }

    }

    setState(state) {

        this.state = state;
        this.currentEvaluationQuestion = 0;
        this.currentEvaluationSet = 0;

    }

    setEvaluationSet(index) {

        this.currentEvaluationSet = index;
        this.currentEvaluationQuestion = 0;

    }

    setEvaluationQuestion(index) {

        this.currentEvaluationQuestion = index;

    }

    openModal(item) {

        let text = item.result;

        let sub = '<div class="footage text-standard-small">' +
            '<div class="correct">' + this.evaluationTable2 + ":<br /> " + item.getCorrectAnswerTitles() + '</div>' +
            '<div class="your">' + this.evaluationTable3 + ":<br /> " + item.getUserAnswerTitles() + '</div>' +
            '</div>';

        if(item.image !== "") {

            text = '<div class="modal-table"><div class="modal-col col-text">' + text + sub + '</div>' +
                '<div class="modal-col col-image"><img class="image" src="' + item.image.sizes.full +  '"></div></div>';

        } else {

            text = text + sub;

        }

        store.state.controller.modal.openCustom(this.modalTitle, text,"evaluation");

    }

    getEvaluationQuestions(set) {

        let questions = store.state.controller.test.sets[set].questions;
        let out = [];
        let i = 0;
        while(i < questions.length) {

            let question = questions[i];

            if(question.isVisible()) {

                out.push(question);

            }

            i = i + 1;

        }

        return out;

    }

    getEvaluationItems(set, question) {

        let items = store.state.controller.test.sets[set].questions[question].items;
        let out = [];
        let i = 0;
        while(i < items.length) {

            let item = items[i];

            if(item.isVisible()) {

                out.push(item);

            }

            i = i + 1;

        }

        return out;

    }

    prevEvaluationQuestion() {

        this.currentEvaluationQuestion--;

        if(this.currentEvaluationQuestion < 0) {

            this.setEvaluationQuestion(0);
            this.currentEvaluationSet--;

            if(this.currentEvaluationSet < 0) {

                this.setEvaluationSet(0);
                this.setState("start");

            }

        }

    }

    nextEvaluationQuestion() {

        this.currentEvaluationQuestion++;

        if(this.currentEvaluationQuestion >= this.getEvaluationQuestions(this.currentEvaluationSet).length) {

            this.setEvaluationQuestion(0);

            if(this.currentEvaluationSet < store.state.controller.test.sets.length -1) {

                this.setEvaluationSet(this.currentEvaluationSet + 1);

            } else {

                this.setEvaluationSet(0);
                this.setState("start");

            }
            
        }

    }
    
    build(construct){

        this.average = construct.average.points;
        this.startTitle = construct.start.screen.title;
        this.startInfo = construct.start.screen.info;
        this.startImage = construct.start.screen.image;
        this.startPoints = construct.start.points;

        this.detailTitle = construct.details.screen.title;
        this.detailText = construct.details.screen.text;
        this.buttonAverage = construct.details.screen.button_average;

        this.evaluationTitle = construct.details.screen.your_title;
        this.evaluationTable1 = construct.details.screen.your_table_header_1;
        this.evaluationTable2 = construct.details.screen.your_table_header_2;
        this.evaluationTable3 = construct.details.screen.your_table_header_3;
        this.buttonEvaluationNext = construct.details.screen.button_next;
        this.buttonEvaluationPrev = construct.details.screen.button_prev;
        this.modalTitle = construct.details.screen.modal_title;
        
        this.buttonDetails = construct.start.screen.button_details;
        this.buttonDownload = construct.details.screen.button_download;
        this.buttonEvaluation = construct.details.screen.button_evaluation;
        this.pdf = construct.download.pdf;
        this.pdfUri = construct.download.pdf_uri;
        this.pdfPost = construct.download.pdf_post;

        this.resultCanvas = '';

        this.averageBg = construct.average.chart_bg;

        //console.log(this);

    }

    start() {

        this.setMaxPoints();
        this.setUserPoints();
        this.setStartMessage();
        this.setStartInfo();

    }

    setStartMessage() {

        let userPoints = this.userPoints;

        let message = {
            points: 0,
            text: "",
        };

        let i = 0;
        while(i < this.startPoints.length) {

            let points = this.startPoints[i];
            if(userPoints < points.points) {

                message = points;

                break;

            }

            i = i + 1;

        }

        this.startMessage = message.text;

    }

    setStartInfo() {

        let info = this.startInfo;

        info = info.replace("[user_points]", this.userPoints);
        info = info.replace("[max_points]", this.maxPoints);

        this.startInfo = info;
        
    }

    setMaxPoints() {

        this.maxPoints = store.state.controller.test.getMaxPoints();

    }

    getMaxChartPoints() {

        return store.state.controller.test.getMaxSetPoints();

    }

    setUserPoints() {

        this.userPoints = store.state.controller.test.getUserPoints();

    }

    getChartLabels() {

        let out = [];

        let sets = store.state.controller.test.sets;

        let i = 0;
        while(i < sets.length) {

            let set = sets[i];
            out.push(set.title.toUpperCase());

            i = i + 1;

        }

        return out;

    }

    getChartAverageData() {

        let datasets = [];
        let sets = store.state.controller.test.sets;

        let averages = this.average;
        let averagesSorted = [];
        let averagePoints = [];

        let i = 0;
        while(i < sets.length) {

            let set = sets[i];

            let j = 0;
            while(j < averages.length) {

                let average = averages[j];

                if(average.id === set.id) {

                    averagesSorted.push(average);

                }

                j = j + 1;

            }

            i = i + 1;

        }

        i = 0;
        while(i < sets.length) {

            let set = sets[i];

            let data = [];
            let pointRadius = [];
            let pointHitRadius = [];
            let pointHoverRadius = [];
            let j = 0;
            while(j < averagesSorted.length) {

                let average = averagesSorted[j];

                if(average.id === set.id) {

                    data.push(average.points);
                    averagePoints.push(average.points);
                    pointRadius.push(15);
                    pointHitRadius.push(15);
                    pointHoverRadius.push(15);

                } else {

                    data.push(0);
                    pointRadius.push(0);
                    pointHitRadius.push(0);
                    pointHoverRadius.push(0);

                }

                j = j + 1;

            }

            datasets.push({
                fill: false,
                borderColor : 'rgba(255,255,255,0)',
                borderWidth: 0,
                data: data,
                pointRadius: pointRadius,
                pointHoverRadius: pointHoverRadius,
                pointHitRadius: pointHitRadius,
                pointBorderWidth: 1,
                pointBorderColor: '#000000',
                pointBackgroundColor: set.color,
            });

            i = i + 1;

        }

        let bg = [{
            fill: false,
            borderColor : '#000000',
            borderWidth: 1,
            data: averagePoints,
            pointRadius: 0 ,
            pointBorderWidth: 0,
            pointBorderColor: 'rgba(255,255,255, 0)',
            pointBackgroundColor: 'rgba(255,255,255, 0)',
            pointHoverRadius: 0
        }];

        let out = datasets.concat(bg);

        return out;

    }

    getChartData() {

        let datasets = [];
        let sets = store.state.controller.test.sets;

        let userPoints = [];

        let i = 0;
        while(i < sets.length) {

            let set = sets[i];
            let points = set.getUserPoints();
            userPoints.push(points);

            let data = [];
            let pointRadius = [];
            let pointHitRadius = [];
            let pointHoverRadius = [];
            let j = 0;
            while(j < sets.length) {

                if(i === j) {
                    //data.push(6);
                    data.push(points);
                    pointRadius.push(15);
                    pointHitRadius.push(15);
                    pointHoverRadius.push(15);

                } else {

                    data.push(0);
                    pointRadius.push(0);
                    pointHitRadius.push(0);
                    pointHoverRadius.push(0);

                }

                j = j + 1;

            }

            datasets.push({
                label: 'Label1',
                fill: false,
                borderColor : 'rgba(255,255,255,0)',
                borderWidth: 1,
                data: data,
                pointHitRadius: pointHitRadius,
                pointRadius: pointRadius,
                pointHoverRadius: pointHoverRadius,
                pointBorderWidth: 1,
                pointBorderColor: '#000000',
                pointBackgroundColor: set.color,
            });

            i = i + 1;

        }

        let bg = [{
            fill: false,
            borderColor : '#000000',
            borderWidth: 1,
            data: userPoints,
            pointRadius: 0 ,
            pointBorderWidth: 0,
            pointBorderColor: 'rgba(255,255,255, 0)',
            pointBackgroundColor: 'rgba(255,255,255, 0)',
            pointHoverRadius: 0
        }];

        let out = datasets.concat(bg);

        return out;

    }

    getPdf() {

        _paq.push(['setCustomUrl', '/download']);
        _paq.push(['setDocumentTitle', 'Download']);
        _paq.push(['trackPageView']);

        let formData = new FormData();
        formData.append('pdf', this.pdfPost);

        let canvas = document.querySelectorAll("#bg-radar canvas")[0];
        let data = canvas.toDataURL();
        formData.append("user_image", data);
        formData.append("user_points", this.userPoints);

        axios.post(this.pdfUri,formData,  {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/pdf'
            }
        }).then((response) => {
            //console.log(response.data);
            const url = window.URL.createObjectURL(new Blob([response.data], {'type' : 'application/pdf'}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'mein-newstest.pdf');
            document.body.appendChild(link);
            link.click();
        }).catch((error) => console.log(error));

    }

}