<script>
import {Radar} from 'vue-chartjs'


export default {
  name: "ResultDetailRadar",
  extends: Radar,
  components: {
    Radar
  },
  computed: {
    chartData() {
      return this.radarChartData;
    },
    data() {
      return this.$store.state.controller.result;
    },
    radarChartOptions() {
      return {
        layout: {
          padding: {
            left: 20,
            right: 20,
            top: 20,
            bottom: 20
          }
        },
        animation: {
          duration: 0,
          onComplete: function () {
            let chartInstance = this.chart,
                ctx = chartInstance.ctx;
            ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            this.data.datasets.forEach(function (dataset, i) {
              let meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function (bar, index) {
                let data = dataset.data[index];
                ctx.fillStyle = "#ffffff";
                ctx.font = "15px font";
                ctx.fillText(data, bar._model.x, bar._model.y);
              });
            });
            //this.data.resultCanvas = this.chart.canvas.toDataURL();
            //console.log(this.$store.state.controller.result.resultCanvas);
          },
        },
        hover: {
          animationDuration: 0,
        },
        responsiveAnimationDuration: 0,
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
          titleFontFamily: 'font',
          titleFontSize: 22,
          titleFontStyle: 'normal',
          titleSpacing: 5,
          bodyFontSize: 0,
          titleMarginBottom: 0,
          yPadding: 15,
          xPadding: 25,
          bodySpacing: 0,
          caretSize: 10,
          displayColors: false,
          backgroundColor: '#000000',
          footerSpacing: 0,
          cornerRadius: 12,
          callbacks: {
            label: function(tooltipItem, data) {
              return "";
            }
          }
        },
        title: {
          display: false,
        },
        scale: {
          title: {
            padding: 10
          },
          gridLines: {
            circular: true,
            color: 'rgba(255,255,255, 0)'
          },
          pointLabels: false,
          angleLines: {
            color: 'rgba(255,255,255, 0)'
          },
          ticks: {
            display: false,
            beginAtZero: true,
            suggestedMin: 0,
            fontColor: 'rgba(255,255,255, 0)',
            fontSize: 12,
            suggestedMax: this.data.getMaxChartPoints()
          },
        },
        responsive: true,
        scales: {
          yAxes: [

          ],
          xAxes: [
             
          ]
        }
      }
    },
    radarChartData() {
      return {
        labels: this.data.getChartLabels(),
        datasets: this.data.getChartData()
      }
    }
  },
  methods: {

  },
  watch: {
    chartData: function (newData, oldData) {
      this.renderChart(this.radarChartData, this.radarChartOptions);
    }
  },
  mounted() {
    this.renderChart(this.radarChartData, this.radarChartOptions);
  }
}
</script>

