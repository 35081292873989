import Question from "@/classes/Question";
import QuestionTypeSinglechoiceItem from "@/classes/QuestionTypeSinglechoiceItem";

export default class QuestionTypeSinglechoice extends Question {

    constructor() {

        super();
        this.type = "singlechoice";

    }

    build(construct, index, num) {

        super.build(construct, index, num);

        let i = 0;
        while(i < construct.items.length) {

            if(construct.items[i].image !== "") {

                this.type = "singlechoice-image";

            }

            let item = new QuestionTypeSinglechoiceItem();
            item.build(construct.items[i], i, this);
            this.items.push(item);

            i = i + 1;

        }

    }

}