import QuestionItem from "@/classes/QuestionItem";

export default class QuestionTypeSwipeItem extends QuestionItem {

    constructor() {

        super();
        this.image = "";
        this.srcset = "";
        this.text = "";

    }

    addAnswers(answer) {
        
        this.userAnswers = [answer];

    }

    build(construct, index, question) {

        super.build(construct, index, question);
        this.image = construct.image;
        this.srcset = construct.srcset;
        this.text = construct.text;
        this.correct = [this.correct];

    }
}