import Question from "@/classes/Question";
import QuestionTypeMultiplechoiceItem from "@/classes/QuestionTypeMultiplechoiceItem";

export default class QuestionTypeMultiplechoice extends Question {

    constructor() {

        super();
        this.type = "multiplechoice";

    }

    build(construct, index, num) {

        super.build(construct, index, num);

        let i = 0;
        while(i < construct.items.length) {

            if(construct.items[i].image !== "") {

                this.type = "multiplechoice-image";

            }

            let item = new QuestionTypeMultiplechoiceItem();
            item.build(construct.items[i], i, this);
            this.items.push(item);

            i = i + 1;

        }

    }

}