import store from '../store';
export default class QuestionItem {

    constructor() {

        this.id = '';
        this.index = 0;
        this.image = '';
        this.correct = [];
        this.condition = '';
        this.correct = [];
        this.text = '';
        this.result = '';

        this.question = '';
        this.userAnswers = [];

    }

    build(construct, index, question) {

        this.question = question;
        this.index = index;
        this.id = construct.id;
        this.correct = construct.correct;
        this.condition = construct.condition;
        this.correct = construct.correct;
        this.text = construct.text;
        this.result = construct.result;

        store.state.controller.test.items[this.id] = this;

    }

    getCorrectAnswerTitles() {

        return this.question.getCorrectAnswerTitles(this.correct).join(", ");

    }

    getUserAnswerTitles() {

        return this.question.getCorrectAnswerTitles(this.userAnswers).join(", ");

    }

    addAnswers(answer) {

        this.removeAnswers();

        if(Array.isArray(answer)) {

            this.userAnswers = answer;

        } else {

            this.userAnswers = [answer];

        }

    }

    isAnswer(answer) {

        let j = 0;
        while(j < this.userAnswers.length) {

            let userAnswer = this.userAnswers[j];
            if(userAnswer === answer) {

                return true;

            }

            j = j + 1;

        }
        
        return false;

    }

    removeAnswer(answer) {

        let index = this.userAnswers.indexOf(answer);

        if(index !== -1) {

            this.userAnswers.splice(index, 1);

        }

    }

    removeAnswers() {

        this.userAnswers.splice(0, this.userAnswers.length);

    }

    hasAnswer(answer) {

        return this.userAnswers.indexOf(answer) >= 0;

    }

    isCorrect() {

        if(this.userAnswers !== '__NONE__' && this.userAnswers[0] !== '__NONE__') {

            let correctAnswers = this.getUserCorrectAnswers();
            if (correctAnswers >= this.correct.length) {

                return true;

            }

        }

        return false;

    }

    getCorrectAnswers() {

        let out = [];

        let i = 0;
        while(i < this.correct.length) {

            let correct = this.correct[i];

            out.push(correct);

            i = i + 1;
        }

        

    }

    getUserCorrectAnswers() {

        let userCorrectAnswers = 0;

        let i = 0;
        let userAnswers = this.userAnswers;
        while(i < userAnswers.length) {

            let userAnswer = userAnswers[i];

            let correctAnswers = this.correct;
            let j = 0;
            while(j < correctAnswers.length) {

                let correctAnswer = correctAnswers[j];

                if(correctAnswer === userAnswer) {

                    userCorrectAnswers = userCorrectAnswers + 1;

                }

                j = j + 1;

            }

            i = i + 1;

        }

        //console.log("------ITEM: " + this.id + " | correct answers: " + userCorrectAnswers);

        return userCorrectAnswers;

    }

    isVisible() {

        if(this.condition !== '') {

            let type = this.condition.operator;
            let itemId = this.condition.item_id;

            if(type === '=') {

                let item = store.state.controller.test.items[itemId];
                let answer = this.condition.answer_id;
                let userAnswers = item.userAnswers;
                
                let i = 0;
                while(i < userAnswers.length) {

                    let userAnswer = userAnswers[i];

                    if(userAnswer === answer) {

                        return true;

                    }

                    i = i + 1;

                }

                return false;

            } else if(type === '!=') {

                let item = store.state.controller.test.items[itemId];
                let answer = this.condition.answer_id;
                let userAnswers = item.userAnswers;

                let i = 0;
                while(i < userAnswers.length) {

                    let userAnswer = userAnswers[i];

                    if(userAnswer === answer) {

                        return false;

                    }

                    i = i + 1;

                }

                return true;

            } else if(type === 'left') {

                let item = store.state.controller.test.items[itemId];
                let answer = "left"
                let userAnswers = item.userAnswers;

                let i = 0;
                while(i < userAnswers.length) {

                    let userAnswer = userAnswers[i];

                    if(userAnswer === answer) {

                        return true;

                    }

                    i = i + 1;

                }

                return false;

            } else if(type === 'right') {

                let item = store.state.controller.test.items[itemId];
                let answer = "right"
                let userAnswers = item.userAnswers;

                let i = 0;
                while(i < userAnswers.length) {

                    let userAnswer = userAnswers[i];

                    if(userAnswer === answer) {

                        return true;

                    }

                    i = i + 1;

                }

                return false;

            } else if(type === 'pos') {

                let item = store.state.controller.test.items[itemId];
                let answer = parseInt(this.condition.answer_id) -1;
                let userAnswers = item.userAnswers;

                let i = 0;
                while(i < userAnswers.length) {

                    let userAnswer = userAnswers[i];

                    if(userAnswer === answer) {

                        return true;

                    }

                    i = i + 1;

                }

                return false;

            }

        }

        return true;

    }

}