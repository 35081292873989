<template>
  <section>
    <div class="wrapper">
      <div class="main" >
        <div class="chart">
          <div class="bg" v-if="data.averageBg !== ''"
               :style="{background: 'url(' + data.averageBg.sizes.full + ')'}"></div>
          <ResultDetailRadar class="user-radar"></ResultDetailRadar>
          <transition name="ts-button">
            <ResultDetailRadarAverage v-show="data.showAverage" class="average-radar"></ResultDetailRadarAverage>
          </transition>
          <div class="label"
               :class="{
                  'is-top' : (((index * 100 / sets.length) * 360) / 100)  === 0,
                  'is-right': (((index * 100 / sets.length) * 360) / 100)  > 0 &&
                  (((index * 100 / sets.length) * 360) / 100)  <= 180,
                  'is-left': (((index * 100 / sets.length) * 360) / 100)  > 180 &&
                  (((index * 100 / sets.length) * 360) / 100) < 460
               }"
               :key="item.id"
               v-for="(item, index) in sets"
               :style="{transform: 'rotate(' + (((index * 100 / sets.length) * 360) / 100) + 'deg)'}">
            <div class="inner" :style="{transform: 'rotate(' + (-((index * 100 / sets.length) * 360) / 100) + 'deg)'}">
              <div class="text">
                <img class="icon" :src="item.icon.sizes.full">
                <span class="title font-weight-bold text-title">{{item.title}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="show-mobile-important">
        <div class="buttons">
          <div class="buttons-cell ani-button--5">
            <button class="button has-title"
                    @click="data.setState('evaluation')">
              <span class="title">{{data.buttonEvaluation}}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ResultDetailRadar from "@/components/ResultDetailRadar";
import ResultDetailRadarAverage from "@/components/ResultDetailRadarAverage";

export default {
  name: "ResultDetail",
  components: {
    ResultDetailRadarAverage,
    ResultDetailRadar,
  },
  computed: {
    sets() {
      return this.$store.state.controller.test.sets;
    },
    data() {
      return this.$store.state.controller.result;
    },
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/result-details';
</style>