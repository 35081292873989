import Vue from 'vue'
import 'es6-promise/auto'
import Vuex from 'vuex'
import User from '../classes/User'
import Controller from '../classes/Controller'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: new User(),
        controller: new Controller()
    },
})
