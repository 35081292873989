import store from '../store';
import ModalInfo from "@/classes/ModalInfo";

export default class Question {

    constructor() {

        this.id = "";
        this.headline = "";
        this.text = "";
        this.currentItem = 0;
        this.items = [];
        this.index = 0;
        this.num = 0;
        this.modal = new ModalInfo();
        this.type = "";
        this.points = "";

        this.answers = [];

    }

    start() {

        store.state.controller.modal.setHelp(this.modal);

    }

    next() {

        if(this.currentItem < this.items.length - 1) {

            this.currentItem++;

            if(!this.items[this.currentItem].isVisible()) {

                this.next();

            }

        } else {

            store.state.controller.test.sets[store.state.controller.test.currentSet].nextQuestion();

        }

        store.state.user.setStorage();

    }

    prev() {

        if(this.currentItem > 0) {

            this.currentItem--;

            if(!this.items[this.currentItem].isVisible()) {

                this.prev();

            }

        } else {

             store.state.controller.test.sets[store.state.controller.test.currentSet].prevQuestion();

        }

    }

    skip() {

        this.items[this.currentItem].removeAnswers();
        this.items[this.currentItem].addAnswers("__NONE__");
        this.next();

    }

    isVisible() {

        let i = 0;
        while(i < this.items.length) {

            let item = this.items[i];
            if(item.isVisible()) {

                return true;

            }

            i = i + 1;

        }

        return false;

    }

    getUserCorrectAnswers() {

        let userCorrectAnswers = 0;

        let i = 0;
        while(i < this.items.length) {

            let item = this.items[i];

            if(item.isVisible()) {

                userCorrectAnswers = userCorrectAnswers + item.getUserCorrectAnswers();

            }

            i = i + 1;

        }

        return userCorrectAnswers;

    }

    getUserPoints() {

        //console.log("---QUESTION: " + this.id);

        let userCorrectAnswers = this.getUserCorrectAnswers();
        let points = this.points;
        let userPoints = 0;

        let i = 0;
        while(i < points.length) {

            let point = points[i];
            if(userCorrectAnswers >= point.correct) {

                userPoints = point.points;
                break;

            }

            i = i + 1;

        }

        return userPoints;

    }

    getMaxPoints(){

       let maxPoints = 0;

       let i = 0;
       while(i < this.points.length) {

           let point = this.points[i];
           if(point.points > maxPoints) {

               maxPoints = point.points

           }

           i = i + 1;

       }

       return maxPoints;

    }

    getQuestionTitleWithoutDep() {

        let text = this.text.replace("[dep_title]", "");
        text = text.replace("<p>", "");
        text = text.replace("</p>", "");
        text = text.replace(/\s{2,}/g, ' ');

        return text;

    }

    getQuestionTitle() {

        let text = this.text;

        if(text.indexOf('[dep_title]') !== -1) {

            let condition = this.items[this.currentItem].condition;

            if(condition !== "") {

                let type = condition.operator;
                let itemId = condition.item_id;

                if(type === '=' || type === '!=') {

                    let item = store.state.controller.test.items[itemId];
                    let userAnswers = item.userAnswers;
                    let answers = item.question.answers;

                    let replace = [];

                    let i = 0;
                    while(i < answers.length) {

                        let answer = answers[i];

                        let j = 0;
                        while(j < userAnswers.length) {

                            if(answer.id === userAnswers[j]) {

                              replace.push('<span class="text-underline">' + answer.title + '</span>');

                            }

                            j = j + 1;

                        }

                        i = i + 1;

                    }

                    if(replace.length > 0) {

                        replace = replace.join(", ");
                        text = text.split("[dep_title]").join(replace);
                        
                    }

                }

            }

        }
        
        return text;

    }

    getCorrectAnswerTitles(ids) {

        let out = [];

        let i = 0;
        while(i < this.answers.length) {

            let answer = this.answers[i];

            let j = 0;
            while(j < ids.length) {

                 if(ids[j] === answer.id) {

                     out.push(answer.title);

                 }

                 j = j + 1;

            }

            i = i + 1;

        }

        return out;

    }

    build(construct, index, num) {

        this.num = num;
        this.index = index;
        this.id = construct.id;
        this.text = construct.screen.text;
        this.modal.text = construct.modal.help;
        this.modal.title = '<b>' + store.state.controller.test.modalHelpTitle + '</b>';
        this.answers = construct.answers;
        this.points = construct.points;

        store.state.controller.test.questions[this.id] = this;

    }

}