import store from '../store';

export default class Teaser {

    constructor(){

        this.title = "";
        this.buttonNext = "";
        this.buttonPrev = "";
        this.buttonStart = "";

        this.currentSlideIndex = 0;
        this.slides = [];

    }

    prevSection() {

        if(this.currentSlideIndex <= 0) {

            store.state.controller.setState("start");

        }

    }

    nextSection() {

        if(this.currentSlideIndex >= this.slides.length - 1) {

            store.state.controller.setState("test");

        }

    }

    onSlideChange(e, newIndex, oldIndex, destIndex) {

        store.state.controller.teaser.currentSlideIndex = newIndex;

    }

    build(construct){

        this.buttonNext = construct.global.button_next;
        this.buttonPrev = construct.global.button_prev;
        this.buttonStart = construct.global.button_start;
        this.title = construct.screen.title;

        let i = 0;
        while(i < construct.screen.slides.length) {

            let jSlide = construct.screen.slides[i];

            this.slides.push(jSlide.text);

            i = i + 1;

        }

    }

}