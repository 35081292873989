import QuestionItem from "@/classes/QuestionItem";

export default class QuestionTypeImagesItem extends QuestionItem {

    constructor() {

        super();
        this.images = "";
        this.image = "";

    }

    build(construct, index, question) {

        super.build(construct, index, question);
        this.images = construct.images;
        this.image = construct.image;

        this.correct = [];
        let i = 0;
        while(i < this.images.length) {

            let image = this.images[i];
            

            if(image.correct) {

                this.correct.push(i);

            }

            i = i + 1;

        }

        this.getCorrectAnswerTitles();

    }

    getCorrectAnswerTitles() {

        let i = 0;
        while(i < this.images.length) {

            let image = this.images[i];
            if(image.correct) {

                return image.title;

            }

            i = i + 1;

        }

        return "";

    }

    getUserAnswerTitles() {

        return this.images[this.userAnswers[0]].title;

    }

}