<template>
  
  <div class="color-black">
    <div class="underlay" @click="data.close()"></div>
    <div class="inner" @click="data.close()">
      <div class="content">
        <div @click="data.close()" class="button has-icon close">
          <span class="icon icon-close"></span>
        </div>
        <img @load="data.isLoaded = true" v-if="data.src !== ''" class="image"
             :src="data.src" />
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'ModalImage',
  computed: {
    data () {
      return this.$store.state.controller.imgmodal;
    }
  },
}
</script>

<style scoped lang="scss">
@import '~@/styles/modal-image';
</style>
