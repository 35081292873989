import Question from "@/classes/Question";
import QuestionTypeCardsItem from "@/classes/QuestionTypeCardsItem";

export default class QuestionTypeCards extends Question {

    constructor() {

        super();
        this.type = "cards";
        this.optional = [];

    }

    build(construct, index, num) {

        super.build(construct, index, num);

        this.optional = construct.screen.optional;

        let i = 0;
        while(i < construct.items.length) {

            let item = new QuestionTypeCardsItem();
            item.build(construct.items[i], i, this);
            this.items.push(item);

            i = i + 1;

        }

    }

    getNum(answer) {

        let num = 0;

        let j = 0;
        while (j < this.items.length) {

            let item = this.items[j];

            if(item.isVisible()) {

                let z = 0;
                while (z < item.userAnswers.length) {

                    if (item.userAnswers[z] === answer) {

                        num = num + 1;

                    }

                    z = z + 1;
                }

            }

            j = j + 1;

        }

        return num;

    }

}