<template>

  <div>
      <transition-group tag="div" name="ts-section" class="inner">
        <div v-show="item.index === set.currentQuestion"
                       class="question"
                        v-for="item in set.questions"
                       :key="item.id"
                       :index="item.index"
                       :sindex="index">
          <SetQuestionSinglechoice :sindex="index"
                                     :index="item.index"
                                     v-if="item.type === 'singlechoice'">
          </SetQuestionSinglechoice>
          <SetQuestionSinglechoiceImage :sindex="index"
                                   :index="item.index"
                                   v-if="item.type === 'singlechoice-image'">
          </SetQuestionSinglechoiceImage>
          <SetQuestionMultiplechoice :sindex="index"
                                     :index="item.index"
                                     v-if="item.type === 'multiplechoice'">
          </SetQuestionMultiplechoice>
          <SetQuestionMultiplechoiceImage :sindex="index"
                                          :index="item.index"
                                          v-if="item.type === 'multiplechoice-image'">
          </SetQuestionMultiplechoiceImage>
          <SetQuestionFolder    :sindex="index"
                                :index="item.index"
                                v-if="item.type === 'folder'">
          </SetQuestionFolder>
          <SetQuestionCards :sindex="index"
                            :index="item.index"
                            v-if="item.type === 'cards'">
          </SetQuestionCards>
          <SetQuestionSwipe :sindex="index"
                            :index="item.index"
                            v-if="item.type === 'swipe'">
          </SetQuestionSwipe>
          <SetQuestionSwipeImage  :sindex="index"
                                  :index="item.index"
                                  v-if="item.type === 'swipe-image'">
          </SetQuestionSwipeImage>
          <SetQuestionImages  :sindex="index"
                              :index="item.index"
                              v-if="item.type === 'images'">
          </SetQuestionImages>
        </div>
      </transition-group>
  </div>

</template>

<script>
import SetQuestionSinglechoice from "@/components/SetQuestionSinglechoice";
import SetQuestionMultiplechoice from "@/components/SetQuestionMultiplechoice";
import SetQuestionFolder from "@/components/SetQuestionFolder";
import SetQuestionCards from "@/components/SetQuestionCards";
import SetQuestionSwipe from "@/components/SetQuestionSwipe";
import SetQuestionMultiplechoiceImage from "@/components/SetQuestionMultiplechoiceImage";
import SetQuestionSinglechoiceImage from "@/components/SetQuestionSinglechoiceImage";
import SetQuestionImages from "@/components/SetQuestionImages";
import SetQuestionSwipeImage from "@/components/SetQuestionSwipeImage";

export default {
  name: "SetQuestions",
  components: {
    SetQuestionSwipeImage,
    SetQuestionImages,
    SetQuestionSinglechoiceImage,
    SetQuestionMultiplechoiceImage,
    SetQuestionSwipe,
    SetQuestionCards,
    SetQuestionMultiplechoice,
    SetQuestionFolder,
    SetQuestionSinglechoice,
  },
  props: [
    'index',
  ],
  computed: {
    test() {
      return this.$store.state.controller.test;
    },
    set() {
      return this.$store.state.controller.test.sets[this.index];
    },
  }
}
</script>

<style scoped>

</style>