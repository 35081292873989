import store from '../store';

export default class Start {

    constructor(){

        this.title = "";
        this.text = "";
        this.button = "";
        this.image = "";

    }

    nextSection() {

        store.state.controller.setState('teaser');

    }


    build(construct){

        this.title = construct.screen.title;
        this.text = construct.screen.text;
        this.button = construct.global.button;
        this.image = construct.screen.image;

    }

}