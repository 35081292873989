<template>

  <transition-group tag="div" name="ts-section" class="set">
    <SetTeaser class="teaser"
               key="setTeaser"
               v-show="data.state === 'teaser'"
               :index="data.index">
    </SetTeaser>
    <SetQuestions class="questions"
                  key="setQuestions"
                  v-show="data.state === 'questions'"
                  :index="data.index">
    </SetQuestions>
  </transition-group>

</template>

<script>
import SetTeaser from "@/components/SetTeaser";
import SetQuestions from "@/components/SetQuestions";

export default {
  name: 'Set',
  components: {
    SetQuestions,
    SetTeaser
  },
  props: [
     "index"
  ],
  computed: {
    test() {
      return this.$store.state.controller.test;
    },
    data() {
      return this.$store.state.controller.test.sets[this.index];
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/set';
@import '~@/styles/question';
</style>
