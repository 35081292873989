import QuestionItem from "@/classes/QuestionItem";

export default class QuestionTypeSinglechoiceItem extends QuestionItem {

    constructor() {

        super();
        this.image = "";
        this.srcset = "";

    }

    build(construct, index, question) {

        super.build(construct, index, question);
        this.image = construct.image;
        this.srcset = construct.srcset;

    }

    isCorrect() {

        if(this.userAnswers !== '__NONE__' && this.userAnswers[0] !== '__NONE__') {

            let correctAnswers = this.getUserCorrectAnswers();
            if (correctAnswers > 0) {

                return true;

            }

        }

        return false;

    }

    getUserCorrectAnswers() {

        let userCorrectAnswers = 0;

        let i = 0;
        let userAnswers = this.userAnswers;
        while(i < userAnswers.length) {

            let userAnswer = userAnswers[i];

            let correctAnswers = this.correct;
            let j = 0;
            while(j < correctAnswers.length) {

                let correctAnswer = correctAnswers[j];

                if(correctAnswer === userAnswer) {

                    userCorrectAnswers = userCorrectAnswers + 1;

                    break;

                }

                j = j + 1;

            }

            i = i + 1;

        }

        return userCorrectAnswers;

    }

}