<template>

  <div class="type-singlechoice">
    <div class="table">
      <div class="col">
        <div class="text-info ani-opacity--3 color-black hide-mobile">{{test.titleSinglechoice}}</div>
        <div class="answers" :class="{'min' : question.answers.length < 4}">
          <div class="answer"
               :style="{transitionDelay: ((index * 0.1) +0.1) +  's'}"
               :key="item.id"
               v-for="(item, index) in question.answers">
            <button class="answer-button" @click="clickHandle(item.id)" :class="{'active' : data.isAnswer(item.id)}">
              <div class="text-title-small title color-black">{{item.title}}</div>
              <div class="icon icon-add"></div>
            </button>
          </div>
        </div>
        <div v-if="question.items.length > 1"
             class="color-black text-info">
          {{question.currentItem + 1}} /
          {{question.items.length}}
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "SetQuestionSinglechoice",
  props: [
    'index',
    'sindex',
  ],
  computed: {
    imgModal() {
      return this.$store.state.controller.imgmodal;
    },
    test() {
      return this.$store.state.controller.test;
    },
    set() {
      return this.$store.state.controller.test.sets[this.sindex];
    },
    question() {
      return this.$store.state.controller.test.sets[this.sindex].questions[this.index];
    },
    data() {
      return this.$store.state.controller.test.sets[this.sindex].questions[this.index].items[this.question.currentItem];
    }
  },
  methods: {
    clickHandle(id) {
      if(this.data.hasAnswer(id)) {
        this.data.removeAnswer(id);
      } else {
        this.data.addAnswers(id);
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/question-singlechoice';
@import '~@/styles/table';
</style>