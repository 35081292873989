<template>

  <div class="type-cards">
    <div class="table">
      <Drop class="col left col-first" @drop="handleDrop" @dragleave="handleDragLeave" @dragenter="handleDragEnter">
        <div class="outer">
          <div class="total-num text-headline">
            {{question.getNum("left")}}
          </div>
        </div>
        <button class="area left" @click="clickHandle('left')" :class="{'active' : data.isAnswer('left')}">
          <div class="icon icon-close"></div>
          <div class="title text-title-small">{{question.optional.buttons.button_left_title}}</div>
        </button>
      </Drop>
      <div class="col middle">
        <transition-group name="ts-section" tag="div" class="group">
          <div class="cards"
               v-show="item.index === question.currentItem"
               :key="item.id"
               v-for="item in question.items">
            <Drag class="drag"
                  @dragstart="handleDragStart"
                  @dragend="handleDragEnd">
              <div class="card">
                <div v-if="question.items.length > 1" class="num color-black text-info">
                  {{question.currentItem + 1}} / {{question.items.length}}
                </div>
                <div class="title text-info color-black space-bottom-sm hide-mobile">
                  {{test.titleCards}}
                  <div class="icon icon-swipe"></div>
                </div>
                <div class="text text-standard text-transform-none" v-html="item.text"></div>
                <div class="footer">&nbsp;</div>
              </div>
            </Drag>
          </div>
        </transition-group>
        <div class="col-footer">&nbsp;</div>
      </div>
      <Drop class="col right col-last" @drop="handleDrop" @dragleave="handleDragLeave" @dragenter="handleDragEnter">
        <div class="outer">
          <div class="total-num text-headline">
            {{question.getNum("right")}}
          </div>
        </div>
        <button class="area left" @click="clickHandle('right')" :class="{'active' : data.isAnswer('right')}">
          <div class="icon icon-correct"></div>
          <div class="title text-title-small">{{question.optional.buttons.button_right_title}}</div>
        </button>
      </Drop>
      <div class="table-mobile">
        <div class="col-mobile">
          <Drop class="outer" @drop="handleDrop" @dragleave="handleDragLeave" @dragenter="handleDragEnter">
            <button class="area left" @click="clickHandle('left')" :class="{'active' : data.isAnswer('left')}">
              <div class="icon icon-close"></div>
              <div class="title text-title-small">{{question.optional.buttons.button_left_title}}</div>
            </button>
          </Drop>
        </div>
        <div class="col-mobile">
          <Drop class="outer" @drop="handleDrop" @dragleave="handleDragLeave" @dragenter="handleDragEnter">
            <button class="area left" @click="clickHandle('right')" :class="{'active' : data.isAnswer('right')}">
              <div class="icon icon-correct"></div>
              <div class="title text-title-small">{{question.optional.buttons.button_right_title}}</div>
            </button>
          </Drop>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { Drag, Drop } from 'vue-drag-drop';

export default {
  name: "SetQuestionCards",
  props: [
    'index',
    'sindex',
  ],
  components: {
    Drag,
    Drop
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    imgModal() {
      return this.$store.state.controller.imgmodal;
    },
    test() {
      return this.$store.state.controller.test;
    },
    set() {
      return this.$store.state.controller.test.sets[this.sindex];
    },
    question() {
      return this.$store.state.controller.test.sets[this.sindex].questions[this.index];
    },
    data() {
      return this.$store.state.controller.test.sets[this.sindex].questions[this.index].items[this.question.currentItem];
    },
  },
  methods: {
    handleDrop(droppedElement, e){
      this.removeDraggingClass();
      let ele = e.target;
      let area = ele.querySelectorAll('.area')[0];
      if (document.createEvent) {
        let evt = document.createEvent('MouseEvents');
        evt.initEvent('click', true, false);
        area.dispatchEvent(evt);
      } else if (document.createEventObject) {
        area.fireEvent('onclick') ;
      } else if (typeof node.onclick == 'function') {
        area.onclick();
      }
    },
    handleDragEnter(droppedElement, e) {
      e.target.classList.add("over");
    },
    handleDragLeave(droppedElement, e) {
      e.target.classList.remove("over");
    },
    handleDragStart(draggedElement, e) {
      e.srcElement.classList.add("dragging");
    },
    handleDragEnd(draggedElement, e) {
      e.srcElement.classList.remove("dragging");
    },
    removeDraggingClass() {
      let ele = document.querySelectorAll(".over");
      let i = 0;
      while(i < ele.length) {
        ele[i].classList.remove("over");
        i = i + 1;
      }
    },
    clickHandle(id) {
      if(this.data.hasAnswer(id)) {
        this.data.removeAnswer(id);
      } else {
        this.data.addAnswers(id);
      }
      this.question.next();
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/question-cards';
@import '~@/styles/table';
</style>