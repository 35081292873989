import QuestionItem from "@/classes/QuestionItem";

export default class QuestionTypeCardsItem extends QuestionItem {

    constructor() {

        super();
        this.text = '';

    }

    addAnswers(answer) {
        
        this.userAnswers = [answer];

    }

    build(construct, index, question) {

        super.build(construct, index, question);
        this.text = construct.text;
        this.correct = [this.correct];

    }

}