export default class ModalImage {

    constructor() {

        this.active = false;
        this.isLoaded = false;
        this.src = '';
        this.timeout = null;
        
    }

    setSrc(src) {

        this.src = "";
        this.src = src;

    }

    open() {

        if(this.timeout != null) {

            clearTimeout(this.timeout);

        }

        this.timeout = setTimeout(function() {

            this.setActive(true);

        }.bind(this), 1);

    }

    close() {

        if(this.timeout != null) {

            clearTimeout(this.timeout);

        }

        this.timeout = setTimeout(function() {
            
            this.src = "";

        }.bind(this), 300);

        this.setActive(false);

    }

    setActive(active) {

        //document.querySelectorAll(".co__img__modal .inner")[0].scrollTop = 0;
        this.active = active;

    }

}