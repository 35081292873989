import store from '../store';
import QuestionTypeSinglechoice from "@/classes/QuestionTypeSinglechoice";
import QuestionTypeMultiplechoice from "@/classes/QuestionTypeMultiplechoice";
import QuestionTypeCards from "@/classes/QuestionTypeCards";
import QuestionTypeSwipe from "@/classes/QuestionTypeSwipe";
import QuestionTypeFolder from "@/classes/QuestionTypeFolder";
import QuestionTypeImages from "@/classes/QuestionTypeImages";

export default class Set {

    constructor(){

        this.state = "teaser";

        this.currentQuestion = 0;
        this.questions = [];

        this.id = '';
        this.title = '';
        this.color = '';
        this.text = '';
        this.image = '';
        this.srcsset = '';
        this.index = 0;
        this.icon = '';

    }

    start() {

        this.questions[this.currentQuestion].start();

    }

    setState(state) {

        this.state = state;

    }

    nextQuestion() {

        if(this.currentQuestion < this.questions.length -1) {

            this.currentQuestion++;

            if(!this.questions[this.currentQuestion].items[
                this.questions[this.currentQuestion].currentItem
                ].isVisible()) {

                this.questions[this.currentQuestion].next();
                
            }

            this.start();

        } else {

            store.state.controller.test.nextSet();
            store.state.controller.test.start();
            store.state.user.setStorage();

        }

    }

    prevQuestion() {

        if(this.currentQuestion > 0) {

            this.currentQuestion--;

            if(!this.questions[this.currentQuestion].items[
                this.questions[this.currentQuestion].currentItem
                ].isVisible()) {

                this.questions[this.currentQuestion].prev();

            }

            this.start();

        } else {

            this.setState("teaser");
            this.start();

        }

    }

    prev() {

        if(this.index <= 0) {

            store.state.controller.test.prevSection();

        } else {

            store.state.controller.test.prevSet();

        }

    }

    next() {

        if(this.index < store.state.controller.test.sets.length) {

            this.setState("questions");
            this.questions[this.currentQuestion].start();

            if(!this.questions[this.currentQuestion].items[
                this.questions[this.currentQuestion].currentItem
                ].isVisible()) {

                this.questions[this.currentQuestion].next();

            }

        } else {
            
            store.state.controller.test.nextSection();

        }

    }

    getUserCorrectAnswers() {

        let userCorrectAnswers = 0;

        let i = 0;
        while(i < this.questions.length) {

            let question = this.questions[i];
            userCorrectAnswers = userCorrectAnswers + question.getUserCorrectAnswers();

            i = i + 1;

        }

        return userCorrectAnswers;

    }

    getUserPoints() {

        let userPoints = 0;

        let i = 0;
        while(i < this.questions.length) {

            let question = this.questions[i];
            userPoints = userPoints + question.getUserPoints();

            i = i + 1;

        }

        return userPoints;

    }

    build(construct, index, num){

        this.index = index;
        this.id = construct.id;
        this.title = construct.global.title;
        this.color = construct.global.color;
        this.text = construct.screen.text;
        this.image = construct.global.image;
        this.srcset = construct.global.srcset;
        this.icon = construct.global.icon;

        let jQuestions = construct.questions;
        let i = 0;
        let ix = 0;
        while(i < jQuestions.length){

            let jQuestion = jQuestions[i];

            let question = null;

            switch(jQuestion.type) {

                case 'singlechoice' : question = new QuestionTypeSinglechoice(); break;
                case 'multiplechoice' : question = new QuestionTypeMultiplechoice(); break;
                case 'cards' : question = new QuestionTypeCards(); break;
                case 'swipe' : question = new QuestionTypeSwipe(); break;
                case 'folder' : question = new QuestionTypeFolder(); break;
                case 'images' : question = new QuestionTypeImages(); break;

            }

            if(question != null) {

                question.build(jQuestion, ix, num);

                this.questions.push(question);

                num = num + 1;
                ix = ix + 1;

            }

            i = i + 1;

        }

    }

}