<template>
  <section class="result">
    <div id="bg-radar">
      <ResultDetailRadar></ResultDetailRadar>
    </div>
    <transition-group tag="div" name="ts-section" class="result-section">
      <ResultTeaser class="result-start" key="resultStart"
                    v-show="data.state === 'start'"></ResultTeaser>
      <ResultDetail id="my-result-chart" class="result-detail" key="resultDetail"
                    v-show="data.state === 'details'"></ResultDetail>
      <ResultEvaluation class="result-evaluation"
                        key="resultEvaluation"
                        v-show="data.state === 'evaluation'">
      </ResultEvaluation>
    </transition-group>

  </section>
</template>

<script>
import ResultTeaser from "@/components/ResultTeaser";
import ResultEvaluation from "@/components/ResultEvalutation";
import ResultDetail from "@/components/ResultDetail";
import ResultDetailRadar from "@/components/ResultDetailRadar";
export default {
  name: "Result",
  components: {ResultDetailRadar, ResultDetail, ResultEvaluation, ResultTeaser},
  computed: {
    data() {
      return this.$store.state.controller.result;
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/result';
</style>