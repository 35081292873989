<template>
   
  <section class="teaser">
    <div class="text-title ani-text--1 space-bottom-md">{{data.title}}</div>
    <div class="container">
      <splide ref="teaserSlide" class="slider" :options="splideOptions" @splide:move="data.onSlideChange">
        <splide-slide v-for="item in data.slides" :key="item" class="slide">
          <div class="text-start-info ani-text--2 text-content" v-html="item"></div>
        </splide-slide>
      </splide>
    </div>
  </section>

</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';

export default {
  data() {
    return {
      splideOptions: {
        rewind : false,
        width: '100%',
        perPage: 1,
        gap    : '0',
        arrows: false,
        pagination: false,
        autoplay: false,
      },
    };
  },
  name: 'Teaser',
  components: {
    Splide,
    SplideSlide
  },
  computed: {
    data () {
      return this.$store.state.controller.teaser;
    },
  },
}
</script>

<style scoped lang="scss">
@import '~@/styles/teaser';
</style>
