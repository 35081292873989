import Question from "@/classes/Question";
import QuestionTypeImagesItem from "@/classes/QuestionTypeImagesItem";

export default class QuestionTypeImages extends Question {

    constructor() {

        super();
        this.type = "images";

    }

    build(construct, index, num) {

        super.build(construct, index, num);

        let i = 0;
        while(i < construct.items.length) {

            let item = new QuestionTypeImagesItem();
            item.build(construct.items[i], i,this);
            this.items.push(item);

            i = i + 1;

        }

    }

}