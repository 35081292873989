import store from '../store';

export default class User {

    setStorage() {

        let test = store.state.controller.test;

        try {

            let answers = [];

            let sets = test.sets;

            let i = 0;
            while(i < sets.length) {

                let set = sets[i];
                let questions = set.questions;

                let j = 0;
                while(j < questions.length) {

                    let question = questions[j];
                    let items = question.items;

                    let z = 0;
                    while(z < items.length) {

                        let item = items[z];
                        let userAnswers = item.userAnswers;
                        let id = item.id;

                        answers.push({
                            "id" : id,
                            "answers" : userAnswers
                        });

                        z = z + 1;

                    }

                    j = j + 1;

                }

                i = i + 1;

            }

            let set = test.sets[test.currentSet];
            let question = set.questions[set.currentQuestion];
            let item = question.items[question.currentItem];

            let last = {
                "set" : {"id" : set.id, "index" : test.currentSet},
                "question" : {"id" : question.id, "index" : set.currentQuestion},
                "item" : {"id" : item.id, "index" : question.currentItem},
                "teaser" : set.state === "teaser"
            };

            let out = {
                "version": store.state.controller.version,
                "last" : last,
                "answers" : answers,
                "finish" : store.state.controller.state === "result"
            };

            localStorage.setItem('user', JSON.stringify(out));

        } catch(e) {}

        /**
        _paq.push(['setCustomUrl', '/' + ]);
        _paq.push(['setDocumentTitle', 'My New Title']);
        _paq.push(['trackPageView']);
         **/

    }

    getStorage() {

        let storage = null;

        try {

            storage = localStorage.getItem('user');

        } catch(e){}

        if(storage  !== null) {

            storage = JSON.parse(storage);


        }

        return storage;

    }

    resetStorage() {

        localStorage.removeItem("user");

    }

}