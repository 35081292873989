<template>

  <div class="type-images">

        <transition-group name="ts-section" tag="div" class="items" >
          <div class="item"
               :class="item.image.orientation"
               v-show="item.index === question.currentItem"
               :key="item.id"
               v-for="item in question.items">
            <div class="img-expander-wrapper">
              <div class="img-expander-holder">
                <div class="img-expander space-bottom-xs">
                  <div class="title text-info color-black space-bottom-xs" @click="expandImage(item.image)">
                    {{test.titleFullscreen}}
                    <div class="icon icon-expand"></div>
                  </div>
                  <div class="tiles">
                    <div class="tile"
                         :style="{transitionDelay : (index * 200) + 'ms'}"
                         :class="{'active' : data.isAnswer(index)}"
                         :key="index"
                         @click="clickHandle(index)"
                         v-for="(tile, index) in item.images">
                      <img class="img-src"
                           :srcset="tile.image.srcset"
                           :src="tile.image.sizes.full" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>


</template>

<script>
export default {
  name: "SetQuestionImages",
  props: [
    'index',
    'sindex',
  ],
  computed: {
    test() {
      return this.$store.state.controller.test;
    },
    set() {
      return this.$store.state.controller.test.sets[this.sindex];
    },
    question() {
      return this.$store.state.controller.test.sets[this.sindex].questions[this.index];
    },
    data() {
      return this.$store.state.controller.test.sets[this.sindex].questions[this.index].items[this.question.currentItem];
    },
    imgModal() {
      return this.$store.state.controller.imgmodal;
    }
  },
  methods: {
    expandImage(src) {
      if(src !== "") {
        this.imgModal.setSrc(src.sizes.full);
        this.imgModal.setActive(true);
      }
    },
    clickHandle(id) {
      if(this.data.hasAnswer(id)) {
        this.data.removeAnswer(id);
      } else {
        this.data.addAnswers(id);
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/question-images';
@import '~@/styles/table';
</style>