import QuestionItem from "@/classes/QuestionItem";

export default class QuestionTypeMultiplechoiceItem extends QuestionItem {

    constructor() {

        super();
        this.image = "";
        this.srcset = "";

    }

    addAnswers(answer) {

        if(this.userAnswers.length > 0) {

            if(this.userAnswers[0] === '__NONE__') {

                this.removeAnswers();

            }

        }

        this.userAnswers.push(answer);

    }

    build(construct, index, question) {

        super.build(construct, index, question);
        this.image = construct.image;
        this.srcset = construct.srcset;

    }
    
}