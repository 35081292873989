import store from '../store';
import Start from "@/classes/Start";
import Teaser from "@/classes/Teaser";
import Modal from "@/classes/Modal";
import Test from "@/classes/Test";
import ModalImage from "@/classes/ModalImage";
import Result from "@/classes/Result";

export default class Controller {

    constructor() {

        this.version = 0;
        this.state = "";

        this.start = new Start();
        this.teaser = new Teaser();
        this.test = new Test();
        this.result = new Result();

        this.modal = new Modal();
        this.imgmodal = new ModalImage();

    }

    setState(state) {

        _paq.push(['setCustomUrl', '/' + state]);
        _paq.push(['setDocumentTitle', state]);
        _paq.push(['trackPageView']);

        this.state = state;

        if(this.state === "result") {

            this.result.start();

        } else if(this.state === 'test') {

            _paq.push(['setCustomUrl', '/test/' + this.test.sets[this.test.currentSet].id]);
            _paq.push(['setDocumentTitle', this.test.sets[this.test.currentSet].id]);
            _paq.push(['trackPageView']);

        }

        this.resize();

    }

    storageAvailable(type) {
        let storage;
        try {
          storage = window[type];
          const x = "__storage_test__";
          storage.setItem(x, x);
          storage.removeItem(x);
          return true;
        } catch (e) {
          return (
            e instanceof DOMException &&
            // everything except Firefox
            (e.code === 22 ||
              // Firefox
              e.code === 1014 ||
              // test name field too, because code might not be present
              // everything except Firefox
              e.name === "QuotaExceededError" ||
              // Firefox
              e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage &&
            storage.length !== 0
          );
        }
    }

    resize() {

        let resizeEvent = window.document.createEvent('UIEvents');
        resizeEvent.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(resizeEvent);

    }

    build(construct) {

        this.version = construct.version;
        this.modal.build(construct.modal);
        this.start.build(construct.start);
        this.teaser.build(construct.teaser);
        this.test.build(construct.test);
        this.result.build(construct.result);

        setTimeout(function(){

            let user = store.state.user.getStorage();

            if(user != null && user.version === this.version) {

                this.modal.open("renew");

            } else {

                if (this.storageAvailable("localStorage")) {
                    store.state.user.resetStorage();
                } 
                
            }

            this.setState("start");

        }.bind(this), 0.1);
        
    }

}