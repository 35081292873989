import Vue from 'vue'
import App from './App.vue'
import axios from 'axios';
import store from './store'
import {polyfill} from "mobile-drag-drop";
import {scrollBehaviourDragImageTranslateOverride} from 'mobile-drag-drop/scroll-behaviour';
import MobileDetect from "mobile-detect";


axios.get(window.appPath, {}).then(response => {

  Vue.config.productionTip = false;
  store.state.controller.build(response.data);

  document.getElementById("loader").remove();

  let md = new MobileDetect(window.navigator.userAgent);
  
  if(md.phone() === null) {
    
    polyfill({
      dragImageTranslateOverride: scrollBehaviourDragImageTranslateOverride,
    });

    window.addEventListener('dragenter', function (event) {event.preventDefault();});
    window.addEventListener('touchmove', function () {});

  }

  new Vue({
    store,
    render: h => h(App),
  }).$mount('#app');
  
});