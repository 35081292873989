<template>
  <section>
    <object class="bg ani-opacity--1"
            :data="data.startImage.sizes.full"
            v-if="data.startImage !== ''">
    </object>
    <div class="space-bottom-ml">
      <div class="ani-text--1 text-title space-bottom-sm" v-html="data.startTitle"></div>
      <div class="ani-text--2 text-start-title title-message" v-html="data.startMessage"></div>
      <div class="ani-text--2 text-standard-great" v-html="data.startInfo"></div>
    </div>
    <div class="buttons space-bottom-lg">
      <div class="buttons-cell ani-button--4">
        <button @click="data.setState('evaluation')" class="button has-title big">
          <span class="title">{{data.buttonEvaluation}}</span>
        </button>
      </div>
    </div>
    <div class="show-mobile-important">
      <div class="buttons">
        <div class="buttons-cell ani-button--5">
          <button class="button has-title"
                  @click="data.setState('details')">
            <span class="title">{{data.buttonDetails}}</span>
          </button>
          </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ResultTeaser",
  computed: {
    data() {
      return this.$store.state.controller.result;
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/result';
</style>