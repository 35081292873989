<template>

  <transition-group tag="section" name="ts-section" class="sets">
          <Set v-show="item.index === data.currentSet"
               :key="item.id"
               v-for="item in data.sets"
               :index="item.index"></Set>
  </transition-group>

</template>

<script>
import Set from "@/components/Set";

export default {
  name: "Test",
  components: {
    Set
  },
  computed: {
    data () {
      return this.$store.state.controller.test;
    },
  },
}
</script>

<style scoped>

</style>