<template>
  
  <section>
        <object class="bg ani-opacity--1"
                :data="data.image.sizes.full"
                v-if="data.image !== ''">
        </object>
        <div class="space-bottom-ml">
          <h1 class="ani-text--1 text-title space-bottom-md" v-html="data.title"></h1>
          <h2 class="ani-text--2 text-start-title" v-html="data.text"></h2>
        </div>
        <div class="buttons">
          <div class="buttons-cell ani-button--4">
            <button @click="data.nextSection()" class="button has-title big">
              <span class="title">{{data.button}}</span>
            </button>
          </div>
        </div>
  </section>

</template>

<script>
export default {
  name: 'Start',
  computed: {
    data () {
      return this.$store.state.controller.start;
    }
  },
}
</script>

<style scoped lang="scss">
@import '~@/styles/start';
</style>
