import Question from "@/classes/Question";
import QuestionTypeFolderItem from "@/classes/QuestionTypeFolderItem";

export default class QuestionTypeFolder extends Question {

    constructor() {

        super();
        this.type = "folder";

    }

    build(construct, index, num) {

        super.build(construct, index, num);

        let i = 0;
        while(i < construct.items.length) {

            let item = new QuestionTypeFolderItem();
            item.build(construct.items[i], i, this);
            this.items.push(item);

            i = i + 1;

        }

    }

    getNum(answer) {

        let i = 0;
        while(i < this.answers.length) {

            let num = 0;
            if(this.answers[i].id === answer) {

                let j = 0;
                while (j < this.items.length) {

                    let item = this.items[j];

                    if(item.isVisible()) {

                        let z = 0;
                        while (z < item.userAnswers.length) {

                            if (item.userAnswers[z] === answer) {

                                num = num + 1;

                            }

                            z = z + 1;
                        }

                    }

                    j = j + 1;

                }

                return num;
                
            }

            i = i + 1;

        }

        return 0;

    }

}