import store from '../store';
import Set from "@/classes/Set";

export default class Test {

    constructor(){

        this.currentSet = 0;

        this.sets = [];

        this.buttonPrev = '';
        this.buttonNext = '';
        this.buttonIgnore = '';
        this.setTitle = '';

        this.titleFolder = '';
        this.titleCards = '';
        this.titleSwipe = '';
        this.titleMultiplechoice = '';
        this.titleSinglechoice = '';
        this.titleFullscreen = '';

        this.modalHelpTitle = '';

        this.questions = {};
        this.items = {};

        this.num = 1;

    }

    start() {

        this.sets[this.currentSet].start();

        //console.log("start");

    }

    nextSet() {

        if(this.currentSet < this.sets.length -1) {

            _paq.push(['setCustomUrl', '/test/' + this.sets[this.currentSet].id]);
            _paq.push(['setDocumentTitle', this.sets[this.currentSet].id]);
            _paq.push(['trackPageView']);


            /**
            console.log("SET")
            console.log("ID: " + this.sets[this.currentSet].id);
            console.log("correct answers: " + this.getUserCorrectAnswers());
            console.log("user points: " + this.getUserPoints());
            console.log("ENDSET");
             **/

            this.currentSet++;
            this.start();

        } else {

            store.state.controller.setState("result");

        }
    }

    prevSet() {

        this.currentSet--;
        this.start();

        if(!this.sets[this.currentSet].questions[this.sets[this.currentSet].currentQuestion].items[
            this.sets[this.currentSet].questions[this.sets[this.currentSet].currentQuestion].currentItem
            ].isVisible()) {

            this.sets[this.currentSet].questions[this.sets[this.currentSet].currentQuestion].prev();

        }

    }

    nextSection() {

        store.state.controller.setState("result");
        this.start();

    }

    prevSection() {

        store.state.controller.setState("teaser");
        this.start();

    }

    getUserCorrectAnswers() {

        let userCorrectAnswers = 0;

        let i = 0;
        while(i < this.sets.length) {

            let set = this.sets[i];
            userCorrectAnswers = userCorrectAnswers + set.getUserCorrectAnswers();

            i = i + 1;

        }

        return userCorrectAnswers;

    }

    getUserPoints() {

        let userPoints = 0;

        let i = 0;
        while(i < this.sets.length) {

            let set = this.sets[i];

            let setPoints = set.getUserPoints();

            userPoints = userPoints + setPoints;

            i = i + 1;

        }

        return userPoints;

    }

    getMaxPoints() {

        let maxPoints = 0;

        let i = 0;
        while(i < this.sets.length) {

             let set = this.sets[i];

             let j = 0;
             while(j < set.questions.length) {

                 let question = set.questions[j];
                 maxPoints = maxPoints + question.getMaxPoints();

                 j = j + 1;

             }

            i = i + 1;

        }

        return maxPoints;

    }

    getMaxSetPoints() {

        let maxPoints = 0;

        let i = 0;
        while(i < this.sets.length) {

            let set = this.sets[i];
            let setPoints = 0;

            let j = 0;
            while(j < set.questions.length) {

                let question = set.questions[j];
                setPoints = setPoints + question.getMaxPoints();

                j = j + 1;

            }

            if(setPoints > maxPoints) {

                maxPoints = setPoints;

            }

            i = i + 1;

        }

        return maxPoints;

    }

    continueTest() {

        this.currentSet = 0;
        this.sets[0].currentQuestion = 0;
        this.sets[0].questions[0].currentItem = 0;

        let user = store.state.user;
        let storage = user.getStorage();

        //console.log(storage);

        let test = store.state.controller.test;
        let sets = test.sets;

        let answers = storage.answers;
        let last = storage.last;

        let stopItem = false;
        let stopSet = false;
        let stopQuestion = false;

        let x = 0;

        let i = 0;
        while(i < sets.length) {

            let set = sets[i];
            let questions = set.questions;

            let j = 0;
            while(j < questions.length) {

                let question = questions[j];
                let items = question.items;

                let z = 0;
                while(z < items.length) {

                    let item = items[z];

                    if(!stopItem) {

                        item.userAnswers = answers[x].answers;
                        this.sets[i].questions[j].currentItem = z;
                        x = x + 1;

                    }

                    if(item.id === last.item.id) {

                        stopItem = true;

                    }

                    z = z + 1;

                }

                if(!stopQuestion) {

                    this.sets[i].currentQuestion = j;
                    store.state.controller.modal.setHelp(question.modal);

                }

                if(question.id === last.question.id) {

                    stopQuestion = true;

                }

                j = j + 1;

            }

            if(!stopSet) {

                this.currentSet = i;
                this.sets[this.currentSet].state = "questions";

            }

            if(set.id === last.set.id) {

                stopSet = true;

            }

            i = i + 1;

        }

        _paq.push(['setCustomUrl', '/continiue']);
        _paq.push(['setDocumentTitle', 'continue']);
        _paq.push(['trackPageView']);

        if(storage.finish) {

            store.state.controller.setState("result");

        } else {

            store.state.controller.setState("test");

            if(last.teaser) {

                this.sets[this.currentSet].state = "teaser";

            }

        }

    }

    resetAll() {

        store.state.controller.modal.close();
        store.state.user.resetStorage();

        let test = store.state.controller.test;
        test.currentSet = 0;

        let sets = test.sets;

        let i = 0;
        while(i < sets.length) {

            let set = sets[i];
            set.currentQuestion = 0;
            let questions = set.questions;

            let j = 0;
            while(j < questions.length) {

                let question = questions[j];
                question.currentItem = 0;
                let items = question.items;

                let z = 0;
                while(z < items.length) {

                    let item = items[z];
                    item.userAnswers = [];

                    z = z + 1;

                }

                j = j + 1;

            }

            i = i + 1;
        }

    }

    build(construct){

        let jGlobal = construct.global;
        this.buttonNext = jGlobal.button_next;
        this.buttonPrev = jGlobal.button_prev;
        this.buttonIgnore = jGlobal.button_ignore;

        let jScreen = construct.screen;
        let jSets = jScreen.sets;

        this.modalHelpTitle = jGlobal.title_help;
        this.setTitle = jScreen.title;
        this.titleFolder = jGlobal.title_folder;
        this.titleFullscreen = jGlobal.title_fullscreen;
        this.titleCards = jGlobal.title_card;
        this.titleSwipe = jGlobal.title_swipe;
        this.titleMultiplechoice = jGlobal.title_multiplechoice;
        this.titleSinglechoice = jGlobal.title_singlechoice;

        let i = 0;
        while(i < jSets.length) {

            let set = new Set();
            set.build(jSets[i], i, this.num);
            this.num = this.num + set.questions.length;

            this.sets.push(set);
            
            i = i + 1;
        }

    }

}